import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorService } from '../../share/form/validators/validator.service';
import { PaymentCardHolderForm } from '../../../../types/payment-card-holder';

export interface MembershipNumberForm {
  membershipNumber: FormControl<string>;
  lastName: FormControl<string>;
  zipcode: FormControl<string>;
}

export interface MembershipNumberForm {
  membershipNumber: FormControl<string>;
  lastName: FormControl<string>;
  zipcode: FormControl<string>;
}

export interface QuickRenewForm {
  membershipFormGroup: FormGroup<MembershipNumberForm>;
  paymentCardHolderFormGroup: FormGroup<PaymentCardHolderForm>;
}

export const membershipErrors = {
  membershipNumber: {
    required: 'Please enter your 16 digit membership number, use numbers only.',
    invalidMembershipFormat: 'Please enter your 16 digit membership number, use numbers only.',
    invalidMembership: 'This member number is not valid.',
  },
  lastName: {
    required: 'Please use letters only.',
    isNotString: 'Please enter your last name, use letters only.',
    invalidLastName: 'This last name does not match the account.',
  },
  zipcode: {
    required: 'Invalid Zip Code',
    invalidZipCode: 'Invalid Zip Code',
    zipNotInRegion:
      'This ZIP Code isn’t inside the AAA Hoosier region. Please check your entry and try again. <a href="https://www.aaa.com/stop">Find a different AAA club >></a>',
  },
};

@Injectable({ providedIn: 'root' })
export class QuickRenewFormVm {
  fb = inject(FormBuilder);
  validator = inject(ValidatorService);
  formGroup = this.create();

  get membershipFormGroup() {
    return this.formGroup.controls.membershipFormGroup;
  }

  get paymentCardHolderFormGroup() {
    return this.formGroup.controls.paymentCardHolderFormGroup;
  }

  create() {
    return this.fb.group<QuickRenewForm>({
      membershipFormGroup: this.fb.group({
        membershipNumber: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required, this.validator.membershipNumberFormat(), this.validator.trimString()],
        }),
        lastName: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required, this.validator.isTextString(), this.validator.trimString()],
        }),
        zipcode: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required, this.validator.zipCodeFormat(), this.validator.trimString()],
        }),
      }),
      paymentCardHolderFormGroup: this.fb.group({
        lastName: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required, this.validator.isTextString()],
        }),
        firstName: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required, this.validator.isTextString()],
        }),
      }),
    });
  }
}
